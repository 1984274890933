import { ContactInterface } from "../models/contactInterface";

export const contactData: ContactInterface = {
    name: 'Maria Piper',
    imageURL: "/images/profile_img.jpg",
    location: 'Alameda, CA',
    region: 'San Francisco Bay Area',
    email: 'maria.b.piper@gmail.com',
    phone: '5106460760',
    displayPhone: '(510) 646-0760',
    linkedIn: {
        username: "in/mariapiper",
        url: "https://www.linkedin.com/in/mariapiper/"
    },
    github: {
        username: "mbtomori",
        url: "https://github.com/mbtomori"
    },
    message: `Contact me for full-time, part-time, and contract opportunities. I also offer program management, software
    engineering, and educational services.`,
}
