import {EducationInterface} from "../models/educationInterface";
export const degrees: EducationInterface[] =
    [{
        id: 0,
        name: 'M.S. Computer Science',
        institution: "Northeastern Unversity",
        url: "https://www.khoury.northeastern.edu/programs/computer-science-ms/",
        date: "Dec 2022"
    },
    {
        id: 1,
        name: 'M.A. History',
        institution: "North Carolina State University at Raleigh",
        url: "https://history.chass.ncsu.edu/grad/",
        date: "Dec 2012"
    },
    {
        id: 2,
        name: "B.A. East Asian Studies",
        institution: "Oberlin College",
        url: "https://www.oberlin.edu/arts-and-sciences/departments/east-asian-studies",
        date: "Jan 2008"
    }
]

export const certifications: EducationInterface[] =
    [{
        id: 0,
        name: 'AWS Certified Cloud Practitioner',
        institution: "Amazon Web Services",
        url: "https://aws.amazon.com/certification/certified-cloud-practitioner/",
        date: "2021"
    },
    {
        id: 1,
        name: 'Certificate in Human Resources Management',
        institution: "UC Berkeley Extension",
        url: "https://extension.berkeley.edu/public/category/courseCategoryCertificateProfile.do?method=load&certificateId=17109",
        date: "2020"
    },
    {
        id: 2,
        name: "High School Social Studies Teaching (N.C.)",
        institution: "North Carolina State University at Raleigh",
        url: "https://ced.ncsu.edu",
        date: "2013"
    }
]