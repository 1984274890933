import { ExperienceInterface } from "../models/experienceInterface";

export const experiences: ExperienceInterface[] =
    [{
        id: '0',
        company: 'Código',
        title: 'Technical Writer (Contract)',
        companyURL: 'https://www.codigo.ai',
        imageURL: '/images/logos/codigo.jpg',
        location: 'San Francisco, CA',
        startDate: 'March 2023',
        endDate: 'present',
        keyAccomplishment: `Edited and composed a technical White Paper with Código founders.`,
        otherResponsibilities:
            [
                `Edited and composed the company’s fundraising white paper to document the company’s background, vision, technological design, process, and use cases in collaboration with the company founders.`,
            ],
    },
    {
        id: '1',
        company: 'Amazon Web Services',
        title: 'Software Engineer Intern',
        companyURL: 'https://aws.amazon.com/backup/',
        imageURL: 'faAws',
        location: 'San Francisco, CA',
        startDate: 'May 2022',
        endDate: 'August 2022',
        keyAccomplishment: `Composed and presented a technical project plan covering the existing software architecture
         in addition to explaining the front- and back-end changes required to add desired debugging tools.`,
        otherResponsibilities:
            [
                `Built the frontend component and backend tools that enabled investigators to diagnose AWS Backup 
                customer issues in 95% less time. Used Java, TypeScript, React, JUnit, Mockito. `,
                `Reviewed and edited coworkers' technical documents and presentations.`,
            ],
    },
    {
        id: '2',
        company: 'Onramp',
        companyURL: 'https://www.onramp.io',
        imageURL: '/images/logos/onramp.svg',
        title: 'Director of Apprenticeship Programs',
        location: 'Oakland, CA',
        startDate: 'December 2018',
        endDate: 'January 2021',
        keyAccomplishment: `Designed and wrote technical content, documentation, guides, training plans, and 
        assessments for apprenticeship program that were up to date with industry trends and best practices.`,
        otherResponsibilities:
            [
                `Collaborated with subject-matter experts and stakeholders to ensure content was accurate and effective 
                for audiences at different levels and for different use cases. `,
                `Wrote easily customizable apprentice handbook, style guides, templates, and technical content to 
                enable others to build high quality, customized apprenticeship programs quickly.`,
                `Achieved 100% conversion rate by utilizing data to make improvements, ensuring apprentice 
                success and client satisfaction.`,
                `Oversaw company's HR function, including policy, compliance, employee relations, payroll, and benefits.`
            ],
    },
    {
        id: '3',
        company: 'Streak CRM',
        companyURL: 'https://www.streak.com',
        imageURL: '/images/logos/streak.jpeg',
        title: 'People Operations Lead',
        location: 'San Francisco, CA',
        startDate: 'March 2018',
        endDate: 'September 2018',
        keyAccomplishment: `Developed and documented a scalable hiring process that increased recruiting team efficiency and 
        enabled the company to grow 40% in six months.`,
        otherResponsibilities:
            [
                `Improved offer to acceptance rate and reduced time to fill in hiring.`,
                `Designed and ran the company's performance review process.`,
                `Oversaw Employee Relations, Payroll, and Employee Rewards.`
            ],
    },
    {
        id: '4',
        company: 'Dev Bootcamp',
        companyURL: 'https://en.wikipedia.org/wiki/Dev_Bootcamp',
        imageURL: '/images/logos/dbc.jpg',
        title: 'Director of Diversity and Human Resource Operations',
        location: 'San Francisco, CA',
        startDate: 'November 2015',
        endDate: 'December 2017',
        keyAccomplishment: `Content and project-managed the redevelopment of the internal diversity training program. 
        The project increased curriculum quality and delivery consistency. Resulted in 83% cost reduction while 
        maintaining high student satisfaction. Contributed presentations, facilitation guides, and student resources.`,
        otherResponsibilities:
            [
                `Composed Kaplan Test Prep’s (Dev Bootcamp parent) multi-year diversity roadmap. Incorporated 
                research-based best practices and metrics to evaluate success.`,
                `Oversaw internal communications with the company president which included writing and editing monthly 
                newsletters and email communications, managing town hall meetings, and administering Confluence, 
                the company wiki.`,
                `Developed and facilitated diversity training for external clients. Content included videos, training 
                guides, and activities.`
            ],
    },
    {
        id: '5',
        company: 'Dev Bootcamp',
        companyURL: 'https://en.wikipedia.org/wiki/Dev_Bootcamp',
        imageURL: '/images/logos/dbc.jpg',
        title: 'Director & Technical Curriculum Developer',
        location: 'San Francisco, CA',
        startDate: 'August 2013',
        endDate: 'November 2015',
        keyAccomplishment: `Oversaw program design, development and technical curriculum. Created and curated 200+ 
        hours of technical content covering version control, HTML & CSS, Ruby, JavaScript, SQL.`,
        otherResponsibilities:
            [
                `Built Dev Bootcamp’s Phase 0 Program, a virtual web development program. 
                Served 800 adult developers annually with a 90% graduation rate and high student satisfaction.`,
                `Developed and executed plans to address improvements identified by student data. Initiatives included  
                migrating to the Canvas Learning Management System, reorganizing modules, and adding rubrics and assessments.`,
                `Optimized the team and roles for efficiency. Scaled the program from one to four US locations. `,
            ],
    },
    {
        id: '6',
        company: 'Vance County Schools',
        companyURL: 'https://www.vcs.k12.nc.us',
        imageURL: '/images/logos/vance.png',
        title: 'World History Teacher',
        location: 'Henderson, NC',
        startDate: 'August 2012',
        endDate: 'June 2013',
        keyAccomplishment: `Designed and taught high school world history. Based lessons on common core and state 
        standards.`,
        otherResponsibilities:
            [
                `Documented lesson plans, activities, and content for others to utilize.`,
                `Employed Moodle and other technologies to educate and assess students.`
            ],
    },
        {
        id: '7',
        company: 'Murakami Board of Education',
        companyURL: 'https://www.city.murakami.lg.jp',
        imageURL: '/images/logos/murakami.png',
        title: 'Assistant Langauge Teacher (JET Programme)',
        location: 'Murakami, Niigata, Japan',
        startDate: 'July 2009',
        endDate: 'June 2010',
        keyAccomplishment: `Created, adapted, and delivered English curriculum to elementary and middle 
        school students.`,
        otherResponsibilities:
            [
                `Documented lesson plans, activities, and content for successor.`,
            ],
    },
    {
        id: '8',
        company: 'NC Department of Juvenile Justice and Delinquency Prevention',
        companyURL: 'https://www.ncdps.gov/our-organization/juvenile-justice-and-delinquency-prevention',
        imageURL: '/images/logos/djjdp.jpeg',
        title: 'Policy Assistant',
        location: 'Raleigh, NC',
        startDate: 'February 2008',
        endDate: 'June 2009',
        keyAccomplishment: `Wrote training, manuals, and forms to increase employee policy compliance. 
        Reduced collection effort by 90%.`,
        otherResponsibilities:
            [
                `Delivered training to delegates across the state.`,
                `Used feedback to develop an improved policy compliance process.`,
                `Reduced policy signature backlog by more than 50%.`,
                `Reported the state of policy compliance to the Department Secretary.`,
            ],
    },
]