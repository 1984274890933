import { SampleInterface } from "../models/sampleInterface";

export const writingSamples: SampleInterface[] =
[
    {
        id: 0,
        category: "Technical",
        title: "Build a Portfolio",
        subtitle: "Create your personal portfolio and resume",
        imageURL: "/images/portfolio.png",
        description: `This is the templatized version of this portfolio, designed so others can quickly and easily 
        deploy a simple portfolio website. The README includes instructions for editing and deploying the site using 
        GitHub and Netlify.`,
        resource: [
            {
                description: "GitHub Repository",
                url: "https://github.com/mbtomori/portfolio-template"
            },
            {
                description: "Deployed App",
                url: "https://your-portfolio-template.netlify.app"
            },
        ],
        dateWritten: "February 2024",
    },
    {
        id: 1,
        category: "Technical",
        title: "Git Started",
        subtitle: "Version Control Workshop and Tutorial",
        imageURL: "/images/git.jpg",
        description: `I developed and delivered this in one of my early software engineering courses to help my 
        classmates learn how to use git and GitHub to collaborate on future projects and better prepare for their
        eventual careers as software engineers. This topic was not covered in any coursework, so I offered to develop
        and deliver this workshop.`,
        resource: [
            {
                description: "View Presentation",
                url: "https://docs.google.com/presentation/d/1NxTohlXr87xIY041OWxAGsA722d6O3ZcIsAilQD75Cg/edit#slide=id.p"
            },
            {
                description: "View Tutorial Repository",
                url: "https://github.com/mbtomori/git-started-with-version-control"
            },
        ],
        dateWritten: "April 2021",
    },
    {
        id: 2,
        category: "Blog",
        title: "Solving the Skills Gap",
        subtitle:" and Lack of Diversity through Corporate Training Programs",
        imageURL: "/images/skills-gap.png",
        description: `A thought-leadership piece that aims to promote apprenticeship programs as a means to increasing diversity at companies."
        and apprenticeship program`,
        resource: [
            {
                description: "View on LinkedIn",
                url: "https://www.linkedin.com/pulse/solving-skills-gap-lack-diversity-through-corporate-training-piper%3FtrackingId=mky6kr73efrDV8fG5IhujA%253D%253D/?trackingId=mky6kr73efrDV8fG5IhujA%3D%3D"
            }
        ],
        dateWritten: "Sep 2020",
    },
    {
        id: 3,
        category: "Education",
        title: "Twitch Training Plan",
        subtitle: "Application Best Practices and Upskilling",
        imageURL: "/images/onramp-training-plan.png",
        description: `Developed for Onramp's apprenticeship program with Twitch, this training plan helps candidates 
        create compelling applications and upskill technically to land their first
        Android development role.`,
        requirement: "*A free account is required to view the plan",
        resource: [
            {
                description: "View Plan*",
                url: "https://app.onramp.io/training/5fb6c782ac4a670017662815"
            },
            {
                description: `Watch "Writing Your Resume"`,
                url: "https://www.youtube.com/embed/qE5ksyfjqlo"
            },
            {
                description: "View Resume Presentation",
                url: "https://docs.google.com/presentation/d/e/2PACX-1vQgA503RMm3QXdcUkVqCGMkjWxDDYHM1LnZRu3fLfI1CRDZEsaDiSZ2qvwde58DiSTglHv3eTH4r0Qg/pub?start=false&loop=false&delayms=3000&slide=id.g8069d60434_0_43"
            },
        ],
        dateWritten: "Fall 2020",
    },
    {
        id: 4,
        category: "Guide",
        title: "Cargo Curious Bike Guide",
        subtitle: "A comprehensive guide to understanding and evaluating the many cargo bike options",
        imageURL: "/images/cargo.jpeg",
        description: `If you're looking to replace driving with a bike, it's hard to beat Alameda! 
        The flat land, gorgeous weather, and investment in cycling infrastructure make it easy! This guide
        aims folks who are curious about cargo bikes understand existing options and get data from local owners.`,
        resource: [
            {
                description: "View Cargo Bike Guide",
                url: "https://bikewalkalameda.org/bwa-resources/cargo-curious-bike-guide/"
            }
        ],
        dateWritten: "July 2023",
    },
    {
        id: 5,
        category: "Guide",
        title: "Create a School Safety Team",
        subtitle: "Making drop off safe and fun for everyone",
        imageURL: "/images/school-safety.jpeg",
        description: `As a Bike Walk Alameda and PTSA member, getting kids to school safely regardless of how they
        get there is important to me. We are working with local schools to understand what works and to share those
        best practices with other interested schools and PTAs.`,
        resource: [
            {
                description: "View School Safety Team Guide",
                url: "https://bikewalkalameda.org/bwa-resources/how-to-create-a-school-safety-team/"
            }
        ],
        dateWritten: "November 2023",
    },
]