import { LanguageInterface } from "../models/languageInterface";

export const languages: LanguageInterface[] =
    [{
        id: 0,
        name: 'Java',
        displayPercent: 27,
    },
    {
        id: 1,
        name: 'Python',
        displayPercent: 17,
    },
    {
        id: 2,
        name: "Markdown/HTML/CSS",
        displayPercent: 17,
    },
    {
        id: 3,
        name: "SQL",

        displayPercent: 15,
    },
    {
        id: 4,
        name: "Java/TypeScript",
        displayPercent: 12,
    },
    {
        id: 5,
        name: "C",
        displayPercent: 6,
    },
    {
        id: 6,
        name: "Ruby",
        displayPercent: 4,
    },
    {
        id: 7,
        name: "R",
        displayPercent: 2,
    },
]