import {HomepageInterface} from "../models/homepageInterface";

export const homepageData: HomepageInterface = {
    heading: "Technical Writer",
    subHeading: "Educator and Software Engineer",
    description: `Technical writer with a background in Education, Program Management, and a Master's in Computer Science`,
    firstButton: {
        buttonText: "Samples",
        buttonURL: "/samples"
    },
    secondButton: {
        buttonText: "Contact",
        buttonURL: "/contact"
    },
}
