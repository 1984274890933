import { SkillInterface } from "../models/skillInterface";

export const generalSkills: SkillInterface[] =
    [{
        id: 0,
        category: 'Writing',
        skills: ["Technical Documentation", "Blogs", "Internal Communications", "Guides"],
    },
    {
        id: 1,
        category: 'Education',
        skills: ["Curriculum Development", "Training and Course Facilitation", "Canvas Learning Management System"],
    },
    {
        id: 2,
        category: "Other",
        skills: ["Project Management", "Program Management", "Human Resources", "Diversity",
            "Equity", "and Inclusion",  "Data Analysis", "People Management"],
    }
]

export const technicalSkills: SkillInterface[] =
    [{
        id: 0,
        category: 'Computer Science',
        skills: ["Data Structures and Algorithms", "Databases"," Data Mining","Cloud Computing"
            ,"Computer Systems"," Web Development", "Software Engineering"],
    },
    {
        id: 1,
        category: 'Frameworks and Libraries',
        skills: ["Pandas","React.js","Node.js"," Express.js"," JUnit"," Mockito"],
    },
    {
        id: 2,
        category: "Databases",
        skills: ["SQLite", "MySQL", "AWS RDS", "MongoDB"],
    },
    {
        id: 3,
        category: "Tools",
        skills: ["git", "GitHub", "RStudio", "IntelliJ", "Jupyter Notebook"],
    },
    {
        id: 4,
        category: "Teamwork & Collaboration",
        skills: ["Agile Development", "Scrum", "Jira", "Asana"],
    },
]