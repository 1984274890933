import { CardInterface } from "../models/cardInterface";
import { faPersonChalkboard, faPenToSquare, faLaptopCode } from "@fortawesome/free-solid-svg-icons";

export const cards: CardInterface[] =
    [
    {
        id: '0',
        imageURL: '/images/tech-curriculum.png',
        imageCaption: 'Introduction to Pair Programming from Student Handbook',
        title: 'Education',
        description: `Experienced technical education program director and curriculum designer.`,
        iconName: faPersonChalkboard,
        backContent: [`I specialize in developing technical programs for adult learners. At Dev Bootcamp, I 
        built and led the preparatory program which taught introductory web development to career changers.
        Most of the students had no prior Computer Science knowledge, so the program and curriculum focused on 
        making programming accessible, relatable, and engaging.`,
        `At Onramp, I leveraged the experience at Dev Bootcamp and in HR to recruit, evaluate, and train high-potential
        candidates in a company's specific technical stack. I developed all content for the apprenticeship program, 
        including assessments, rubrics, projects in addition to program implementation.`],
        contentURL: "./about",
        contentTitle: "Learn more about me",
    },
    {
        id: '1',
        imageURL: '/images/packingList.png',
        imageCaption: 'Packit Kiddo Packing List Application (Work In Progress)',
        title: 'Software',
        description: `I've spent the past three years studying Computer Science and building software that solves
        real-world problems.`,
        iconName: faLaptopCode,
        backContent: [`At Amazon Web Services, I added debugging tools to existing software. This required the creation
         of more complex components and adapting the software's architectural model to handle more complex data. The 
         tools transformed a lengthy process into a single-click solution.`,
         `I am currently adapting a classroom project into a consumer-facing packing list
         application for parents of children with different reading abilities. The application empowers parents to delegate
         packing to their children, allowing kids to be responsible and develop independence.`],
        contentURL: "https://github.com/mbtomori",
        contentTitle: "View my GitHub",
    },
        {
        id: '2',
        imageURL: '/images/tech-writing.png',
        imageCaption: 'Instructions for utilizing git for a group project',
        title: 'Writing',
        description: `I write documentation, blogs, newsletter articles, and guides on technical and non-technical 
        subjects.`,
        iconName: faPenToSquare,
        backContent: [`I believe that knowledge that isn't documented is already lost. As a result, my teams 
        prioritized writing and maintaining documentation so that any team member can perform any role or task at 
        a moment's notice.`,
        `I write technical documentation for software projects as well as technical curriculum for developers. 
        This writing focuses on helping others achieve technical objectives through clear and concise explanations and instructions.`,
        `Additional compositions include program development guides, persuasive articles, essays, and flyers.`,],
        contentURL: "/samples",
        contentTitle: "View selected writing samples",
    },
]