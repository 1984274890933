import { AboutInterface } from "../models/aboutInterface";
import { faPersonChalkboard, faPenToSquare, faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";


export const aboutDetails: AboutInterface[] =
[
    {
        id: 0,
        heading: 'Background',
        imageURL: faPersonChalkboard,
        paragraphs: [`I moved to the San Francisco Bay Area in 2013 after earning my master’s and teaching 
        world history in North Carolina. I felt limited as a classroom teacher and wanted to help more 
        students develop skills to be successful in a rapidly changing economy. My initial goal was to 
        learn web development (at Dev Bootcamp) and join an ed tech company.`],
    },
    {
        id: 1,
        heading: 'Teaching',
        imageURL: faLaptopCode,
        paragraphs: [`As a student at Dev Bootcamp, I identified places where my experience as a teacher could 
        enhance the existing program. I joined the staff after graduating and spent the next few years re-engineering 
        and leading the remote preparatory program called Phase 0. In addition to managing a team of instructors and 
        program managers, I developed curriculum, student guides, assessments, and other resources. I loved being 
        able to iterate and improve the program, and I am grateful to have worked with so many people who went 
        on to become successful software engineers.`],
    },
        {
        id: 2,
        heading: 'What now?',
        imageURL: faPenToSquare,
        paragraphs: [`After Kaplan Test Prep acquired Dev Bootcamp, I transitioned into HR, where I could focus on 
        Diversity and Training efforts. Although I found HR work interesting and challenging, I missed writing 
        technical content. So, I moved to Onramp, where I combined my HR and technical experience to build 
        apprenticeship programs for non-traditional candidates seeking their first tech job. When the pandemic 
        hit in 2020, I decided to earn a master’s degree in computer science to strengthen my technical foundation. 
        I graduated with a Master’s in Computer Science from Northeastern University in December 2022. Since then, 
        I’ve been working on personal projects and volunteering in my local Alameda community.`],
    },
    {
        id: 3,
        heading: 'What Now?',
        imageURL: faEnvelope,
        paragraphs: [`I am interested in full-time, part-time, and contract technical writing roles. 
        I also love building programs and software, so if you have a project I can help with, please reach out!`],
    }
]