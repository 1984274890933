import { VolunteerInterface } from "../models/volunteerInterface";

export const volunteerInfo: VolunteerInterface[] =
[
    {
        id: 0,
        organization: "Bike Walk Alameda",
        organizationURL: "https://bikewalkalameda.org",
        title: "Board Member and Advocate",
        startDate: "Dec 2022",
        endDate: "present",
    },
    {
        id: 1,
        organization: "Bay Farm PTSA",
        organizationURL: "https://bayfarmptsa.org",
        title: "Historian, TK/K Coordinator, Room Parent",
        startDate: "Sep 2021",
        endDate: "present",
    },
    {
        id: 2,
        organization: "Buddhist Temple of Alameda",
        organizationURL: "https://www.btoa.org",
        title: "ABWA Member and Volunteer",
        startDate: "Jan 2022",
        endDate: "present",
    },
]