
export const headerData = {
    name: "Maria Piper",
    heading1: {
        name: "About",
        url: "/about"
    },
    heading2: {
        name: "Resume",
        url: "/resume"
    },
    heading3: {
        name: "Writing Samples",
        url: "samples"
    },
    heading4: {
        name: "Contact",
        url: "contact"
    },
}
